<template>
  <div class="">
    <div class="d-flex align-items-center justify-content-center vh-100">
      <div class="text-center">
        <h1 class="display-1 fw-bold">404</h1>
        <p class="fs-3"><span class="text-danger">Opps!</span> {{ $t('page-not-found') }}</p>
        <p class="lead">
          {{ $t('page-does-not-exist') }}

        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Page',
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/variables.scss";


</style>